@import "../../../assets/styles/variables.scss";

.card-health-container {
  padding: 1.2rem 0.9rem;
  color: $text-white;
  border-radius: 3px;

  p {
    margin: 0;
  }
}

.bg {
  &-health {
    background-color: $bg-green;
  }

  &-unhealth {
    background-color: $bg-red;
  }
}
