.card-link {
  display: block;
  border-radius: 5px;
  transition: 0.3s;

  .card-container {
    position: relative;
    padding: 10px;
    min-width: 100px;
    width: 100%;
    height: 100%;
    min-height: 190px;
    background-color: #338333;
    border-radius: 5px;
  }

  .card-icon {
    position: absolute;
    right: 30px;
    font-size: 90px;
    color: rgba($color: #000, $alpha: 25%);
    z-index: 0;
    transform: translateY(70%);
  }

  .card-title {
    position: relative;
    color: #fff;
    z-index: 5;
  }

  .card-data {
    font-size: 40px;
    color: #fff;
  }
}

.card-link:hover {
  transform: scale(1.02);
  box-shadow: 0 5px 10px 10px rgba($color: #000, $alpha: 25%);
  transition: 0.3s;
}
