.page-header {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.filter-Healthy {
  margin-right: 1rem !important;

  input {
    padding-right: 5rem;
  }
}
