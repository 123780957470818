.home-page-container {
  padding: 20px 50px;
  display: grid;
  justify-content: center;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1535px) {
  .home-page-container {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
}
